<script setup>
</script>
<template>
  <!-- ====== Contact Section Start -->
  <section
    class="bg-white py-20 text-white"
    id="contactus"
    style="background-color: #536367fc"
  >
    <div class="container w-full m-auto">
      <div class="flex flex-wrap lg:justify-between -mx-4">
        <div class="w-full m-auto">
          <div class="mb-12 lg:mb-0 flex flex-col">
            <!-- <span class="block mb-4 text-base text-primary font-semibold">
              Contact Us
            </span> -->
            <div class="flex flex-col items-center">
              <h2
                class="text-dark mb-6 uppercase font-bold"
                style="font-size: 26px"
              >
                GET IN TOUCH WITH US
                
              </h2>
              <div class="line"></div>
            </div>
            
            <div class="flex items-center">
              <div
                class="
                  flex flex-col
                  items-center
                  mb-8
                  max-w-[370px]
                  w-full
                  m-auto
                "
              >
                <div
                  class="
                    max-w-[60px]
                    sm:max-w-[70px]
                    w-full
                    h-[60px]
                    sm:h-[70px]
                    flex
                    items-center
                    justify-center
                    mr-6
                    overflow-hidden
                    bg-primary bg-opacity-5
                    text-primary
                    rounded
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    width="48"
                  >
                    <path
                      d="M24 44Q19.85 44 16.2 42.425Q12.55 40.85 9.85 38.15Q7.15 35.45 5.575 31.8Q4 28.15 4 24Q4 19.85 5.575 16.2Q7.15 12.55 9.85 9.85Q12.55 7.15 16.2 5.575Q19.85 4 24 4Q28.15 4 31.8 5.575Q35.45 7.15 38.15 9.85Q40.85 12.55 42.425 16.2Q44 19.85 44 24Q44 28.15 42.425 31.8Q40.85 35.45 38.15 38.15Q35.45 40.85 31.8 42.425Q28.15 44 24 44ZM21.85 40.95V36.85Q20.1 36.85 18.9 35.55Q17.7 34.25 17.7 32.5V30.3L7.45 20.05Q7.2 21.05 7.1 22.025Q7 23 7 24Q7 30.5 11.225 35.35Q15.45 40.2 21.85 40.95ZM36.55 35.55Q38.75 33.15 39.875 30.175Q41 27.2 41 24Q41 18.7 38.1 14.375Q35.2 10.05 30.35 8.05V8.95Q30.35 10.7 29.15 12Q27.95 13.3 26.2 13.3H21.85V17.65Q21.85 18.5 21.175 19.05Q20.5 19.6 19.65 19.6H15.5V24H28.4Q29.25 24 29.8 24.65Q30.35 25.3 30.35 26.15V32.5H32.5Q33.95 32.5 35.05 33.35Q36.15 34.2 36.55 35.55Z"
                    />
                  </svg>
                </div>
                <div class="w-full">
                  <h4 class="font-bold text-dark text-xl mb-1">Our Location</h4>
                  <p class="text-base text-body-color">
                    Wz 13c 2ndfloor vasishth park,pankha road,new delhi 110046
                  </p>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  items-center
                  mb-8
                  max-w-[370px]
                  w-full
                  m-auto
                "
              >
                <div
                  class="
                    max-w-[60px]
                    sm:max-w-[70px]
                    w-full
                    h-[60px]
                    sm:h-[70px]
                    flex
                    items-center
                    justify-center
                    mr-6
                    overflow-hidden
                    bg-primary bg-opacity-5
                    text-primary
                    rounded
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    width="48"
                  >
                    <path
                      d="M3 42Q1.8 42 0.9 41.1Q0 40.2 0 39V9Q0 7.8 0.9 6.9Q1.8 6 3 6H45Q46.2 6 47.1 6.9Q48 7.8 48 9V39Q48 40.2 47.1 41.1Q46.2 42 45 42ZM29.55 39H45Q45 39 45 39Q45 39 45 39V9Q45 9 45 9Q45 9 45 9H3Q3 9 3 9Q3 9 3 9V39Q3 39 3 39Q3 39 3 39H3.35Q5.55 35.55 8.975 33.55Q12.4 31.55 16.45 31.55Q20.5 31.55 23.925 33.55Q27.35 35.55 29.55 39ZM16.45 28Q18.95 28 20.7 26.25Q22.45 24.5 22.45 22Q22.45 19.5 20.7 17.75Q18.95 16 16.45 16Q13.95 16 12.2 17.75Q10.45 19.5 10.45 22Q10.45 24.5 12.2 26.25Q13.95 28 16.45 28ZM37.8 36.6 41.65 32.8 38.8 28.75H35.3Q34.85 27.5 34.675 26.425Q34.5 25.35 34.5 24.05Q34.5 22.75 34.675 21.7Q34.85 20.65 35.3 19.35H38.8L41.65 15.3L37.8 11.5Q35.05 13.75 33.525 17.05Q32 20.35 32 24.05Q32 27.75 33.525 31.05Q35.05 34.35 37.8 36.6ZM7.15 39H25.75Q23.95 36.9 21.525 35.725Q19.1 34.55 16.45 34.55Q13.8 34.55 11.375 35.725Q8.95 36.9 7.15 39ZM16.45 25Q15.2 25 14.325 24.125Q13.45 23.25 13.45 22Q13.45 20.7 14.325 19.85Q15.2 19 16.45 19Q17.75 19 18.6 19.85Q19.45 20.7 19.45 22Q19.45 23.25 18.6 24.125Q17.75 25 16.45 25ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Z"
                    />
                  </svg>
                </div>
                <div class="w-full">
                  <h4 class="font-bold text-dark text-xl mb-1">Phone Number</h4>
                  <p
                    class="text-base text-body-color"
                    style="width: 276px; letter-spacing: 1px"
                  >
                    (+91)9810143734,9654305729,9717724523
                  </p>
                </div>
              </div>
              <div
                class="
                  flex flex-col
                  items-center
                  mb-8
                  max-w-[370px]
                  w-full
                  m-auto
                "
              >
                <div
                  class="
                    max-w-[60px]
                    sm:max-w-[70px]
                    w-full
                    h-[60px]
                    sm:h-[70px]
                    flex
                    items-center
                    justify-center
                    mr-6
                    overflow-hidden
                    bg-primary bg-opacity-5
                    text-primary
                    rounded
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    width="48"
                  >
                    <path
                      d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM24 24.9 7 13.75V37Q7 37 7 37Q7 37 7 37H41Q41 37 41 37Q41 37 41 37V13.75ZM24 21.9 40.8 11H7.25ZM7 13.75V11V13.75V37Q7 37 7 37Q7 37 7 37Q7 37 7 37Q7 37 7 37Z"
                    />
                  </svg>
                </div>
                <div class="w-full">
                  <h4 class="font-bold text-dark text-xl mb-1">
                    Email Address
                  </h4>
                  <p class="text-base text-body-color">
                    info@ambitiongloballogistics.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Contact Section End -->
</template>
<style scoped>
.line {
  width: 112px;
  height: 58px;
  border-bottom:6px solid rgb(255, 255, 255);
  position: absolute;
  
}
</style>