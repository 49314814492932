<template>
  <div>
    <header class="text-gray-600 body-font">
      <div class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <router-link
          to="/"
          class="
            flex
            title-font
            font-medium
            items-center
            text-gray-900
            mb-4
            md:mb-0
          "
        >
          <img src="./assets/logo.jpg" style="width: 142px" />
          <!-- <span class="ml-3 text-xl">Tailblocks</span> -->
        </router-link>
        <nav
          class="
            md:ml-auto
            flex flex-wrap
            items-center
            text-base
            justify-center
          "
          style="font-size: 20px"
        >
          <router-link to="/" class="mr-5 hover:text-gray-900">Home</router-link
          >&nbsp;

          <!-- <a class="mr-5 hover:text-gray-900">Home</a> -->
          <a href="#services" class="mr-5 hover:text-gray-900">Services</a
          >&nbsp;
          <a href="#contactus" class="mr-5 hover:text-gray-900">Contact Us</a
          >&nbsp;
          <a href="#aboutus" class="mr-5 hover:text-gray-900 cursor-pointer">About Us</a>
          <!-- <a class="mr-5 hover:text-gray-900">Contact us</a> -->
        </nav>
      </div>
    </header>
    <div class="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide"><img src="./assets/cargo1.jpg" /></div>
        <div class="swiper-slide"><img src="./assets/cargo2.jpg" /></div>
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->

      <div class="swiper-scrollbar"></div>
    </div>
  </div>

  <router-view />
  <footer class="text-gray-600 body-font">
    <div class="px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
      <div class="flex items-center">
        <router-link
          to="/"
          class="
            flex
            title-font
            font-medium
            items-center
            text-gray-900
            mb-4
            md:mb-0
          "
        >
          <img src="./assets/logo.jpg" />
          <!-- <span class="ml-3 text-xl">Tailblocks</span> -->
        </router-link>
        <p
          class="
            text-sm text-gray-500
            sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0
            mt-4
          "
        >
          © 2020 Ambition Logistics —
          <a
            href="https://gigtechservices.com/"
            class="text-gray-600 ml-1"
            rel="noopener noreferrer"
            target="_blank"
            >@gigtechservices</a
          >
        </p>
      </div>

      <span
        class="
          inline-flex
          sm:ml-auto sm:mt-0
          mt-4
          justify-center
          sm:justify-start
        "
      >
        <a class="text-gray-500">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path
              d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
            ></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-500">
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              stroke="none"
              d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
            ></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </footer>
</template>

<script setup>
import { onMounted } from "@vue/runtime-core";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

onMounted(() => {
  const swiper = new Swiper(".swiper", {
    // Optional parameters
    modules: [Autoplay],
    direction: "horizontal",
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2000,
    },
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
    },
  });
});
</script>

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #e8bac0;
  clip-path: ellipse(300% 100% at 300% 0%);
}
</style>
