<script setup>
</script>

<template>
  <section
    class="bg-white py-20"
    id="aboutus"
    style="background-color: rgb(216 224 194 / 99%)"
  >
    <div class="container w-full m-auto">
      <div class="flex flex-wrap lg:justify-between -mx-4">
        <div class="w-full m-auto">
          <div class="mb-12 lg:mb-0 flex flex-col">
            <!-- <span class="block mb-4 text-base text-primary font-semibold">
              Contact Us
            </span> -->
            <div class="flex flex-col items-center">
              <h2
                class="
                  text-dark
                  mb-6
                  uppercase
                  font-bold
                  
                "
                style="letter-spacing: 13px;font-size: 26px;"
              >
                About Us
                
              </h2>
             

              <div class="line"></div>
            </div>
            
            <div class="flex justify-between pt-4">
              <p
              class="px-28"
                style="
                  font-family: Hermann;
                  font-size: 16px;
                  letter-spacing: 1px;
                  line-height: 48px;
                "
              >
                <b>AGLPL</b> is a company that provides turnkey
                <b>import/export</b> solution to organizations looking to spread
                their wings and jump up to the next league. Our focus has always
                been on delivering complete peace of Mind to all companies we
                associate with. Providing end to end solutions starting from
                planning the warehousing work, right down to execution till it
                begins to operate.
                <b
                  >We believe in building long term relationships, and that’s
                  why majority of our new business comes from existing clients
                  who decide to work with us, year after year</b
                >. We pride ourselves in helping businesses grow and maximize
                their potential.
              </p>
              <div style="width: 2625px" class="pl-3">
                <img src="../assets/ambitionPoster.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.title h2::before {
  background: #fff;
}
.line {
  width: 112px;
  height: 54px;
  border-bottom:6px solid gray;
  position: absolute;
  
}
.title h2::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  width: 60px;
  height: 4px;
  background: #000;
}
</style>